import React from 'react';
import 'assets/scss/komsek.scss';

import SEO from 'components/seo';

const InternalServerError = () => {
  return (
    <>
      <SEO title="Chyba 500" />
      <article className="fullscreen">
        <div className="container">
          <div className="columns">
            <div className="column col-6 col-md-9 col-sm-12" style={{marginTop: "25%"}}>
              <h1>500 Internal Server Error</h1>
              <p>Server nebyl schopen Váš požadavek dokončit.</p>
            </div>
          </div>
        </div>
      </article>
    </>
  );
};

export default InternalServerError;
